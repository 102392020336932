import * as React from "react"
import H1 from "../utils/H1";
import H2 from "../utils/H2";
import H3 from "../utils/H3"
import H5 from "../utils/H5";
import Button from "../utils/Button";
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import BannerSlider from "../components/BannerSlider";
import Layout from "../components/layout"
import Seo from "../components/seo"
import Paragraph from "../utils/Paragraph";
import SetTile from "../components/common/SetTile";
import CompanyTile from "../components/CompanyTile";
import { StaticQuery, graphql } from "gatsby"

const Stories = (props) => (
    <StaticQuery
        query={graphql`
        query {
            allWpPortfolio {
                nodes {
                  title
                  portfolio {
                    description
                    link
                  }
                  featuredImage {
                    node {
                      mediaItemUrl
                    }
                  }
                }
            }
          }
        `}

        render={data => (
            <Layout>
                <Seo title="Portfolio" />
                {/* <div className="h-400px bg-stories bg-center bg-fixed bg-no-repeat bg-cover relative">
                    <div className="overlay absolute w-full h-full top-0 left-0 opacity-80 ">

                    </div>
                    <div className="absolute w-full h-full flex flex-col justify-center items-center">
                        <H1 className="text-white bg-none">Company Stories</H1>
                        <Paragraph className="text-white text-center lg:w-7/12 w-10/12 mt-8">Flying Point Industries has partnered with a variety of successful companies. Explore their latest achievements and relavent news stories that have been recently published on blog sites and the like.</Paragraph>
                    </div>
                </div> */}
                <div className="lg:px-10% px-5% pb-20 bg-white w-full flex flex-col justify-center items-center">
                    {/* <h1 className="text-center lg:text-left text-blue bg-none text-4xl font-bold">A Selection of Our Portfolio Companies</h1> */}
                    <div className="grid md:grid-cols-5 grid-cols-2 auto-rows-auto md:gap-y-0 gap-y-12 md:mt-20 mt-12 w-full">
                        {data.allWpPortfolio.nodes.map((row, index) => (
                            <CompanyTile portfolio={true} data={row} key={index}></CompanyTile>
                        ))}
                    </div>
                </div>
            </Layout>
        )}
    />

)
export default Stories

